<template>
  <!--begin::Group-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">{{ group.name }}
          <span class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                v-b-modal.modal-group-setting
                v-b-tooltip.hover.v-dark="$t('MANAGEMENT.GROUP_SETTING_TOOLTIP_TEXT')">
            <Settings/>
          </span>
          <span class="d-block text-muted font-weight-lighter pt-2 font-size-sm">{{ group.description }}</span>
        </h3>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Table-->
      <div class="table-responsive">
        <b-table
            id="group-table"
            class="table-vertical-center"
            show-empty
            :items="group.members"
            :fields="fields"
            :per-page="0"
            :current-page="group.pagination.page">
          <template #empty="scope">
            <h6>{{ $t("MANAGEMENT.EMPTY_GROUP_MEMBERS_TABLE") }}</h6>
          </template>
          <template #head()="data">
            <span class="pl-0 text-muted font-weight-lighter">{{ data.label }}</span>
          </template>
          <template #cell(name)="data">
            <router-link
                :to="{ name: 'employee', params: { id: data.item.id } }">
              <div class="label label-lg bg-light-primary font-weight-bolder label-inline mr-2 py-8 px-6 float-left">{{ data.item.name_first.charAt(0).toUpperCase() + "" + data.item.name_last.charAt(0).toUpperCase() }}</div>
              <p class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg mt-2 mb-0">{{ data.item.name_first + " " + data.item.name_last }}</p>
              <span>{{ data.item.email }}</span>
            </router-link>
          </template>
        </b-table>
      </div>
      <!--end::Table-->
      <span class="d-block font-weight-lighter mt-6 ml-3 font-size-md">{{ $t("MANAGEMENT.MEMBERS_IN_GROUP_TOTAL") }} {{ group.pagination.total }}</span>
      <!--begin: Pagination-->
      <Pagination name="group-table"
                  :pagination="group.pagination"
                  @changePageLimit="changePageLimit"></Pagination>
      <!--end: Pagination-->
    </div>
    <!--end::Body-->
    <!--begin::Group Setting Modal-->
    <GroupSettingModal :group="group" />
    <!--end::Group Setting Modal-->
  </div>
  <!--end::Group-->
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_GROUP } from "@/core/services/store/management.module";

import Settings from "@/view/layout/svg/Settings";
import Pagination from "@/view/layout/pagination/Pagination";
import GroupSettingModal from "@/view/layout/modal/GroupSettingModal";

export default {
  name: "Group",
  components: {
    Settings,
    Pagination,
    GroupSettingModal
  },
  mounted() {
    this.$store.dispatch(GET_GROUP, { id: this.$route.params.id }).then(() => {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("MANAGEMENT.GROUPS"), route: "/management/groups" },
        { title: `${this.group.name}` }
      ]);
    });
  },
  data() {
    return {
      fields: [
        { key: 'name', label: this.$t("MANAGEMENT.MEMBERS_IN_GROUP_TITLE") }
      ],
    }
  },
  computed: {
    ...mapGetters([
      "group"
    ])
  },
  methods: {
    changePageLimit(val) {
      this.$store.dispatch(GET_GROUP, { id: this.$route.params.id, params: val } )
    }
  }
}
</script>

<style scoped>

</style>
