<template>
  <!--begin::Group Setting Modal-->
  <b-modal
      id="modal-group-setting"
      ref="modal"
      centered
      @hidden="resetModal"
  >
    <template #modal-header="{ close }">
      <div class="w-100">
        <h5 class="float-left font-weight-bolder text-dark">{{ $t('MANAGEMENT.GROUP_EDIT_TITLE') }}</h5>
        <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
      </div>
    </template>
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <div class="form-group row">
        <span class="col-5">{{ $t("MANAGEMENT.GROUP_SETTING_NAME") }}</span>
        <span class="col-5 font-weight-bolder">{{ group.name }}</span>
      </div>
      <div class="form-group row">
        <span class="col-5 pt-2">
          {{ $t("MANAGEMENT.IN_REPORTS_TABLE_TITLE") }}:
          <span class="d-block text-muted font-weight-lighter pt-2 font-size-sm">{{ $t("MANAGEMENT.IN_REPORTS_TABLE_DESC") }}</span>
        </span>
        <span class="col-5">
          <span class="switch">
            <label>
              <input type="checkbox" @click="group.visibleInReports = !group.visibleInReports" :checked="group.visibleInReports" name="select"/>
              <span></span>
            </label>
          </span>
        </span>
      </div>
    </form>
    <template #modal-footer>
      <div class="w-100">
        <b-button
            variant="light-primary"
            class="float-left font-weight-bolder"
            @click="resetModal">
          {{ $t("MANAGEMENT.CANCEL_BUTTON") }}
        </b-button>
        <p class="float-right">
          <b-button
              class="font-weight-bolder"
              variant="primary"
              @click="handleOk">
            {{ $t("MANAGEMENT.EDIT_BUTTON") }}
          </b-button>
        </p>
      </div>
    </template>
  </b-modal>
  <!--end::Group Setting Modal-->
</template>

<script>

import { PUT_GROUP } from "@/core/services/store/management.module";

export default {
  name: "GroupSettingModal",
  props: {
    group: {
      type: Object,
    }
  },
  methods: {
    resetModal() {
      this.$bvModal.hide('modal-group-setting');
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      let form = {
        visibleInReports: this.group.visibleInReports
      }
      this.$store.dispatch(PUT_GROUP, { form, id: this.group.id });
      this.$nextTick(() => {
        this.$bvModal.hide('modal-group-setting');
      });
    }
  }
}
</script>

<style scoped>

</style>